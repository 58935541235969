<template>
  <easel-container
  >
    <easel-shape
      v-for="(line, i) in lines"
      :key="i"
      form="rect"
      :dimensions="line.d"
      :x="line.x"
      :y="line.y"
      :fill="enable === true? '#bcddd4' : '#e6e6e6'"
    ></easel-shape>
    <easel-container
      v-if="value!==0"
      :align="['center','center']"
      :x="curProgress.x"
      :y="curProgress.y"
      :rotation="curProgress.rotation"
    >
      <easel-shape
        v-if="value!==0"
        form="rect"
        :dimensions="[10, 5]"
        fill="#07b39b"
      />
      <easel-shape
        form="star"
        fill="#07b39b"
        :dimensions="[10, 3, 0]"
        :x="5"
        :y="-7.5"
      />
    </easel-container>
  </easel-container>
</template>

<script>

import Vue from 'vue'
import {
  reactive, onMounted, onUnmounted,
} from '@vue/composition-api'

Vue.use(require('vue-easeljs'))

export default {
  props: {
    enable: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Number,
      require: true,
      default: 0,
    },
    lines: {
      type: Array,
      require: true,
      default: () => [],
    },
    startPoint: {
      type: Object,
      require: true,
      default() { return { x: 0, y: 0 } },
    },
    points: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  setup(props) {
    const curProgress = reactive({
      x: props.startPoint.x,
      y: props.startPoint.y,
      rotation: 0,
      direction: 'right',
      target: null,
      moving: null,
    })
    const setDirection = direction => {
      if (curProgress.direction !== direction) {
        curProgress.direction = direction
        switch (direction) {
          default:
          case 'right':
            curProgress.rotation = 0
            break
          case 'left':
            curProgress.rotation = 180
            break
          case 'up':
            curProgress.rotation = -90
            break
          case 'down':
            curProgress.rotation = 90
            break
        }
      }
    }
    const chooseNextTarget = () => {
      let i = props.points.indexOf(curProgress.target)
      i = (i + 1) % props.points.length
      if (i === 0) {
        curProgress.x = props.startPoint.x
        curProgress.y = props.startPoint.y
        setDirection(props.points[0][2])
      } else {
        // eslint-disable-next-line prefer-destructuring
        curProgress.x = props.points[i - 1][0]
        // eslint-disable-next-line prefer-destructuring
        curProgress.y = props.points[i - 1][1]
        setDirection(props.points[i][2])
      }
      curProgress.target = props.points[i]
    }
    const progressing = () => {
      const speed = 5
      if (!curProgress.target) {
        // eslint-disable-next-line prefer-destructuring
        curProgress.target = props.points[0]
      }

      const go = () => {
        const diffX = curProgress.target[0] - curProgress.x
        const diffY = curProgress.target[1] - curProgress.y
        const distance = Math.sqrt((diffX ** 2) + (diffY ** 2))
        if (distance < 30) { // distance < speed
          chooseNextTarget()

          return
        }

        let moveX
        let moveY
        if (diffX === 0) {
          moveX = 0
          moveY = diffY > 0 ? speed : -speed
        } else {
          const percent = speed / distance
          moveX = diffX * percent
          moveY = diffY * percent
        }

        curProgress.x += moveX
        curProgress.y += moveY

        if (Math.abs(moveX) < Math.abs(moveY) * 2) {
          const direction = moveY > 0 ? 'down' : 'up'
          setDirection(direction)
          // if (curProgress.direction !== direction) {
          //   curProgress.direction = direction
          //   curProgress.rotation = direction === 'up' ? -90 : 90
          // }
        } else {
          const direction = moveX > 0 ? 'right' : 'left'
          setDirection(direction)
          // if (curProgress.direction !== direction) {
          //   curProgress.direction = direction
          //   curProgress.rotation = direction === 'right' ? 0 : 180
          // }
        }
      }
      curProgress.moving = setInterval(go, 50);
    }
    progressing()

    onMounted(() => {

    })

    onUnmounted(() => {

    })

    return {
      curProgress,

      progressing,
    }
  },
}
</script>
