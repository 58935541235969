<template>
  <v-card>
    <v-card-title>
      <span>Present-Day Power</span>
    </v-card-title>
    <v-card-text>
      <vue-apex-charts
        type="area"
        height="400"
        :options="chartOptions"
        :series="series"
      />
      <v-overlay
        v-model="showOverlay"
        absolute
        color="white"
        opacity="0.75"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
.row-chart {
  .v-card__title {
    height: 80px;
  }
}
</style>

<script>
import VueApexCharts from 'vue-apexcharts'

import {
  reactive, ref, watch, onBeforeUnmount,
} from '@vue/composition-api'
import axios from '@axios'
import dayjs from 'dayjs'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    siteId: { type: String, default: '' },
  },
  setup(props) {
    const series = ref([])
    const showSoc = ref(false)
    const chartOptions = reactive({
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: ['straight', 'straight', 'straight', 'smooth'],
        width: [2],
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'center',
        fontSize: '14px',
        fontFamily: '"Gotham", sans-serif',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'HH:mm',
          datetimeUTC: false,
        },
        tickAmount: 10,
        min: new Date(new Date().setHours(0, 0, 0, 0)).getTime(), // start date
        max: new Date(new Date().setHours(24, 0, 0, 0)).getTime(), // end date
      },
      yaxis: [
        {
          seriesName: 'Grid/Generator',
          title: {
            text: 'Power (kW)',
          },
          labels: {
            // Should not have decimal
            formatter: value => value,
          },
        },
        {
          seriesName: 'Grid/Generator',
          show: false,
        },
        {
          seriesName: 'Grid/Generator',
          show: false,
        },
        {
          show: showSoc.value,
          seriesName: 'SOC',
          opposite: true,
          title: {
            text: 'SOC (%)',
          },
          min: 0,
          max: 100,
          labels: {
            formatter: val => val,
          },
        },
      ],
      fill: {
        type: ['gradient', 'gradient', 'gradient', 'solid'],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.5,
          opacityTo: 0.2,
          stops: [0, 100, 100, 100],
        },
      },
      tooltip: {
        enable: true,
        shared: true,
        followCursor: true,
        x: {
          show: true,
          format: 'dd-MM-yyyy HH:mm:ss',
          formatter: undefined,
        },
      },
      colors: ['rgb(218, 28, 92)', 'rgb(0, 180, 156)', 'rgb(0, 174, 239)', 'rgb(72, 47, 146)'],
    })
    const showOverlay = ref(false)
    const hideOverlay = () => {
      showOverlay.value = false
    }
    const showFetching = () => {
      showOverlay.value = true
    }
    showFetching()

    let timeoutLoadData = null
    const getTodaySummary = async () => {
      axios
        .get(`/device/summarypower?site_id=${props.siteId}&date=${dayjs().format('YYYY-MM-DD')}`)
        .then(response => {
          const {
            INV, GME, LOAD, BAT,
          } = response.data
          series.value = [
            {
              name: 'Grid/Generator',
              type: 'area',
              data: GME.map(item => ({ y: parseFloat(item.PACTO.toFixed(3)), x: item.date })),
            },
            {
              name: 'Solar',
              type: 'area',
              data: INV.map(item => ({ y: parseFloat(item.PACTO.toFixed(3)), x: item.date })),
            },
            {
              name: 'Load',
              type: 'area',
              data: LOAD.map(item => ({ y: parseFloat(item.PACTO.toFixed(3)), x: item.date })),
            },
          ]

          if (BAT.length > 0) {
            showSoc.value = true
            series.value.push({
              name: 'SOC (%)',
              type: 'line',
              data: BAT.map(item => ({ y: parseFloat(item.BBTSC.toFixed(3)), x: item.date })),
            })
          } else {
            showSoc.value = false
          }
        })
        .catch(error => console.log('error get Current-day Power', error))
        .finally(hideOverlay)

      timeoutLoadData = setTimeout(getTodaySummary, 15000)
    }

    // Todo: cancel axios if data is fetching
    watch(() => props.siteId, () => {
      clearTimeout(timeoutLoadData)
      getTodaySummary()
    })

    if (props.siteId) {
      getTodaySummary()
    }

    onBeforeUnmount(() => {
      clearTimeout(timeoutLoadData)
    })

    return {
      series,
      chartOptions,

      showOverlay,
      hideOverlay,
      showFetching,
    }
  },
}
</script>
