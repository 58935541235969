<template>
  <div>
    <v-row class="row-chart">
      <v-col
        cols="12"
        lg="3"
      >
        <v-card class="mb-5">
          <v-card-title>
            Devices
          </v-card-title>
          <v-card-text class="px-2">
            <v-treeview
              v-model="selectDevice"
              dense
              selectable
              selected-color="primary"
              :open="['INV']"
              :items="devices"
              item-key="device_id"
              item-text="device_name"
            ></v-treeview>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>
            Indexes
          </v-card-title>
          <v-card-text
            class="px-2 overflow-y-auto"
            style="height: 400px"
          >
            <v-treeview
              v-model="selectIndex"
              dense
              selectable
              selected-color="primary"
              :open="['INV']"
              :items="availableIndexes"
              :value="selectIndex"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        lg="9"
      >
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                <v-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateSelected"
                      readonly
                      v-bind="attrs"
                      :prepend-icon="icons.mdiCalendar"
                      class="shrink"
                      v-on="on"
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          color="primary"
                          small
                          :loading="analysisLoading"
                          @click="queryData"
                        >
                          Query
                        </v-btn>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateSelected"
                    color="primary"
                    :max="`${new Date().toISOString().substr(0, 10)}`"
                    @input="datePicker = false"
                  ></v-date-picker>
                </v-menu>
                <div class="ml-3">
                  <v-btn small color="secondary" @click="showPvImage">PV Image</v-btn>
                  <vue-easy-lightbox
                    moveDisabled
                    :imgs="pvImage"
                    :visible="visibleLightbox"
                    @hide="onHideLightbox"
                  ></vue-easy-lightbox>
                </div>
                <v-spacer></v-spacer>
                <span class="subtitle-2">
                  <v-btn-toggle
                    v-model="toggleDayType"
                  >
                    <v-btn
                      depressed
                      small
                    >
                      Day
                    </v-btn>
                    <v-btn
                      depressed
                      small
                    >
                      Month
                    </v-btn>
                    <v-btn
                      depressed
                      small
                    >
                      Year
                    </v-btn>
                    <v-btn
                      depressed
                      small
                    >
                      Total
                    </v-btn>
                  </v-btn-toggle>
                </span>
              </v-card-title>
              <v-card-text>
                <line-chart
                  class="mb-6"
                  :height="400"
                  :chart-data="analysisData"
                  :options="analysisOptions"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
          >
            <v-row>
              <v-col v-if="showDcPower">
                <v-card>
                  <v-card-title>DC Power</v-card-title>
                  <v-card-text>
                    <analysis-scatter
                      :data="dcPowerData"
                      :annotation="true"
                      x-title="Voltage (V)"
                      y-title="Current (A)"
                    ></analysis-scatter>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col v-if="showTemperature">
                <v-card>
                  <v-card-title>Temperature</v-card-title>
                  <v-card-text>
                    <analysis-scatter
                      :data="dcTemperatureData"
                      x-title="Temperature (°C)"
                      y-title="Power (W)"
                    ></analysis-scatter>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  ref, reactive, watch, computed,
} from '@vue/composition-api'

import axios from '@axios'
import dayjs from 'dayjs'
import VueEasyLightbox from 'vue-easy-lightbox'
import { mdiCalendar } from '@mdi/js'
import { LineChart } from 'vue-chart-3'
import store from '@/store'
import colors from '@/configs/colors'

import chartJsConfig from './charts/chartJsConfig'
import 'chartjs-adapter-dayjs'
import AnalysisScatter from './charts/AnalysisScatter.vue'

export default {
  components: {
    AnalysisScatter,
    LineChart,
    VueEasyLightbox,
  },
  props: {
    siteId: String,
    siteInfo: Object,
  },
  setup() {
    const analysisLoading = ref(false)
    const analysisOptions = reactive(chartJsConfig.analysisLineChart.options)
    const analysisData = reactive({
      labels: [],
      datasets: [],
    })
    const showDcPower = ref(false)
    const dcPowerData = reactive({
      datasets: [],
      max: [10],
    })

    const showTemperature = ref(false)
    const dcTemperatureData = reactive({
      datasets: [],
    })

    // Todo: use site's pv image?
    const pvImage = ref('/images/pv-map.png')
    const visibleLightbox = ref(false)
    const showPvImage = () => {
      visibleLightbox.value = true
    }
    const onHideLightbox = () => {
      visibleLightbox.value = false
    }

    const siteStartDate = computed(() => store.getters['sites/startDate'])
    const emiDeviceId = computed(() => store.getters['sites/firstEMI'])
    const selectDevice = ref([store.state.devices.INV[0]?.device_id])
    const selectIndex = ref(['PACTO'])
    const toggleDayType = ref(0)
    const dateSelected = ref(new Date().toISOString().substr(0, 10))
    const datePicker = ref(false)
    const devices = computed(() => [
      {
        device_id: 'INV',
        device_name: 'Inverter',
        children: store.state.devices.INV,
      },
    ])
    const indexes = [
      {
        id: 'INV',
        name: 'Inverter Index',
        children: [
          {
            id: 'ENEDA',
            name: 'Daily Energy (kWh)',
            yAxis: 'ykWh',
            dayOnly: true,
          },
          {
            id: 'INVEF',
            name: 'Inv. efficiency (%)',
            yAxis: 'yPercent',
            dayOnly: true,
          },
          {
            id: 'ENETO',
            name: 'Lifetime Energy (kWh)',
            yAxis: 'ykWh',
            dayOnly: false,
          },
          {
            id: 'PDCTO',
            name: 'Input Power (kW)',
            yAxis: 'y',
            dayOnly: true,
          },
          {
            id: 'PACTO',
            name: 'Active Power (kW)',
            yAxis: 'y',
            dayOnly: true,
          },
          {
            id: 'QACTO',
            name: 'Reactive Power (kW)',
            yAxis: 'y',
            dayOnly: true,
          },
          {
            id: 'PFACT',
            name: 'Power Factor',
            yAxis: 'yEtc',
            dayOnly: true,
          },
          {
            id: 'FREQA',
            name: 'Grid Frequency (Hz)',
            yAxis: 'yHz',
            dayOnly: true,
          },
          {
            id: 'IPHSA',
            name: 'Grid A Current (A)',
            yAxis: 'yA',
            dayOnly: true,
          },
          {
            id: 'IPHSB',
            name: 'Grid B Current (A)',
            yAxis: 'yA',
            dayOnly: true,
          },
          {
            id: 'IPHSC',
            name: 'Grid C Current (A)',
            yAxis: 'yA',
            dayOnly: true,
          },
          {
            id: 'VLIAB',
            name: 'Grid AB Line Voltage (V)',
            yAxis: 'yV',
            dayOnly: true,
          },
          {
            id: 'VLIBC',
            name: 'Grid BC Line Voltage (V)',
            yAxis: 'yV',
            dayOnly: true,
          },
          {
            id: 'VLICA',
            name: 'Grid CA Line Voltage (V)',
            yAxis: 'yV',
            dayOnly: true,
          },
          {
            id: 'VPHSA',
            name: 'Grid A Phase Voltage (V)',
            yAxis: 'yV',
            dayOnly: true,
          },
          {
            id: 'VPHSB',
            name: 'Grid B Phase Voltage (V)',
            yAxis: 'yV',
            dayOnly: true,
          },
          {
            id: 'VPHSC',
            name: 'Grid C Phase Voltage (V)',
            yAxis: 'yV',
            dayOnly: true,
          },
          {
            id: '01IDC',
            name: 'PV1 Input Current (A)',
            yAxis: 'yA',
            dayOnly: true,
          },
          {
            id: '02IDC',
            name: 'PV2 Input Current (A)',
            yAxis: 'yA',
            dayOnly: true,
          },
          {
            id: '03IDC',
            name: 'PV3 Input Current (A)',
            yAxis: 'yA',
            dayOnly: true,
          },
          {
            id: '04IDC',
            name: 'PV4 Input Current (A)',
            yAxis: 'yA',
            dayOnly: true,
          },
          {
            id: '05IDC',
            name: 'PV5 Input Current (A)',
            yAxis: 'yA',
            dayOnly: true,
          },
          {
            id: '06IDC',
            name: 'PV6 Input Current (A)',
            yAxis: 'yA',
            dayOnly: true,
          },
          {
            id: '07IDC',
            name: 'PV7 Input Current (A)',
            yAxis: 'yA',
            dayOnly: true,
          },
          {
            id: '08IDC',
            name: 'PV8 Input Current (A)',
            yAxis: 'yA',
            dayOnly: true,
          },
          {
            id: '09IDC',
            name: 'PV9 Input Current (A)',
            yAxis: 'yA',
            dayOnly: true,
          },
          {
            id: '01VDC',
            name: 'PV1 Input Voltage (V)',
            yAxis: 'yV',
            dayOnly: true,
          },
          {
            id: '02VDC',
            name: 'PV2 Input Voltage (V)',
            yAxis: 'yV',
            dayOnly: true,
          },
          {
            id: '03VDC',
            name: 'PV3 Input Voltage (V)',
            yAxis: 'yV',
            dayOnly: true,
          },
          {
            id: '04VDC',
            name: 'PV4 Input Voltage (V)',
            yAxis: 'yV',
            dayOnly: true,
          },
          {
            id: '05VDC',
            name: 'PV5 Input Voltage (V)',
            yAxis: 'yV',
            dayOnly: true,
          },
          {
            id: '06VDC',
            name: 'PV6 Input Voltage (V)',
            yAxis: 'yV',
            dayOnly: true,
          },
          {
            id: '07VDC',
            name: 'PV7 Input Voltage (V)',
            yAxis: 'yV',
            dayOnly: true,
          },
          {
            id: '08VDC',
            name: 'PV8 Input Voltage (V)',
            yAxis: 'yVA',
            dayOnly: true,
          },
          {
            id: '09VDC',
            name: 'PV9 Input Voltage (V)',
            yAxis: 'yV',
            dayOnly: true,
          },
          {
            id: 'CATMP',
            name: 'Cabinet Temperature (℃)',
            yAxis: 'yTemp',
            dayOnly: true,
          },
        ],
      },
    ]
    const showDayOnlyIndex = ref(true)
    const availableIndexes = computed(() => {
      if (showDayOnlyIndex.value) {
        return indexes[0].children
      }

      return indexes[0].children.filter(item => item.dayOnly === showDayOnlyIndex.value)
    })
    const getInverterData = deviceId => devices.value[0].children.find(item => item.device_id === deviceId)
    const fetchDayData = async () => {
      analysisLoading.value = true
      axios
        .get('/device/daily_analysis', {
          params: {
            date: dateSelected.value,
            deviceId: selectDevice.value.join(),
            params: selectIndex.value.join(),
          },
        })
        .then(response => {
          analysisData.labels = []
          analysisData.datasets = []
          Object.entries(response.data).forEach(([deviceId, data]) => {
            colors.reset()
            selectIndex.value.forEach(param => {
              const deviceData = getInverterData(deviceId)
              const paramData = indexes[0].children.find(item => item.id === param)
              analysisData.datasets.push({
                yAxisID: `${paramData.yAxis}`,
                data: data.map(item => ({ y: parseFloat(item[param].toFixed(3)), x: item.publishTimestamp })),
                label: `${deviceData.device_name || 'N/A'} - ${paramData.name}`,
                lineTension: 0.2,
                pointStyle: 'circle',
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: 'transparent',
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                backgroundColor: colors.color(),
                borderColor: colors.color(),
              })
              colors.next()
            })
          })
        })
        .catch(error => console.log('error get day data', error))
        .finally(() => { analysisLoading.value = false })

      if (showDcPower.value) {
        // eslint-disable-next-line no-use-before-define
        await fetchDcPower()
      }
      if (showTemperature.value) {
        // eslint-disable-next-line no-use-before-define
        await fetchDcTemperature()
      }
    }
    const fetchMonthData = async () => {
      analysisLoading.value = true
      axios
        .get('/device/monthly_analysis', {
          params: {
            year_month: dayjs(dateSelected.value).format('YYYY-MM'),
            deviceId: selectDevice.value.join(),
            params: selectIndex.value.join(),
          },
        })
        .then(response => {
          analysisData.labels = []
          analysisData.datasets = []

          // Only ENETO allowed (ENETO_ON, ENETO_OFF, ENETO_TOTAL), so value is fixed.
          Object.entries(response.data).forEach(([deviceId, data]) => {
            colors.reset()
            const deviceData = getInverterData(deviceId)
            const paramData = indexes[0].children.find(item => item.id === 'ENETO')
            analysisData.datasets.push({
              yAxisID: `${paramData.yAxis}`,
              data: data.map(item => ({ y: parseFloat(item.ENETO_TOTAL.toFixed(3)), x: item.date })),
              label: `${deviceData.device_name || 'N/A'} - ${paramData.name}`,
              lineTension: 0.2,
              pointStyle: 'circle',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              backgroundColor: colors.color(),
              borderColor: colors.color(),
            })
            colors.next()
          })
        })
        .catch(error => console.log('error get month data', error))
        .finally(() => { analysisLoading.value = false })
    }
    const fetchYearData = async () => {
      analysisLoading.value = true
      axios
        .get('/device/yearly_analysis', {
          params: {
            year: dayjs(dateSelected.value).format('YYYY'),
            deviceId: selectDevice.value.join(),
            params: selectIndex.value.join(),
          },
        })
        .then(response => {
          analysisData.labels = []
          analysisData.datasets = []

          // Only ENETO allowed, so value is fixed.
          Object.entries(response.data).forEach(([deviceId, data]) => {
            colors.reset()
            const deviceData = getInverterData(deviceId)
            const paramData = indexes[0].children.find(item => item.id === 'ENETO')
            analysisData.datasets.push({
              yAxisID: `${paramData.yAxis}`,
              data: data.map(item => ({ y: parseFloat(item.ENETO.toFixed(3)), x: `${item.date}-01` })),
              label: `${deviceData.device_name || 'N/A'} - ${paramData.name}`,
              lineTension: 0.2,
              pointStyle: 'circle',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              backgroundColor: colors.color(),
              borderColor: colors.color(),
            })
            colors.next()
          })
        })
        .catch(error => console.log('error get year data', error))
        .finally(() => { analysisLoading.value = false })
    }
    const fetchTotalData = async () => {
      analysisLoading.value = true
      axios
        .get('/device/totally_analysis', {
          params: {
            start_year: dayjs(siteStartDate.value).format('YYYY'),
            deviceId: selectDevice.value.join(),
            params: selectIndex.value.join(),
          },
        })
        .then(response => {
          analysisData.labels = []
          analysisData.datasets = []

          // Only ENETO allowed, so value is fixed.
          Object.entries(response.data).forEach(([deviceId, data]) => {
            colors.reset()
            const deviceData = getInverterData(deviceId)
            const paramData = indexes[0].children.find(item => item.id === 'ENETO')
            analysisData.datasets.push({
              yAxisID: `${paramData.yAxis}`,
              data: data.map(item => ({ y: parseFloat(item.ENETO.toFixed(3)), x: `${item.year}-01-01` })),
              label: `${deviceData.device_name || 'N/A'} - ${paramData.name}`,
              lineTension: 0.2,
              pointStyle: 'circle',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              backgroundColor: colors.color(),
              borderColor: colors.color(),
            })
            colors.next()
          })
        })
        .catch(error => console.log('error get month data', error))
        .finally(() => { analysisLoading.value = false })
    }
    const fetchDcPower = async () => {
      const dcParam = [...new Set(selectIndex.value.filter(e => /.*(IDC|VDC)$/.test(e)).map(item => (item.replace(/^0{1}/, '').replace(/(IDC|VDC)/, ''))))].sort()
      axios
        .get('/device/daily_dc_power', {
          params: {
            date: dateSelected.value,
            deviceId: selectDevice.value.join(),
            dc_param: dcParam.join(','),
          },
        })
        .then(response => {
          dcPowerData.datasets = []
          dcPowerData.max = [10]
          Object.entries(response.data).forEach(([deviceId, data]) => {
            colors.reset()
            dcParam.forEach(param => {
              const deviceData = getInverterData(deviceId)
              const dataIdx = `${param.padStart(2, '0')}`
              dcPowerData.datasets.push({
                data: data[dataIdx].map(item => ({ y: parseFloat((item[`${dataIdx}IDC`] || 0).toFixed(3)), x: parseFloat((item[`${dataIdx}VDC`] || 0).toFixed(3)), date: item.publishTimestamp })),
                label: `${deviceData.device_name || 'N/A'} - MPPT${param}`,
                backgroundColor: colors.color(),
                borderColor: colors.color(),
              })
              colors.next()
              dcPowerData.max.push(Math.max(...data[dataIdx].map(item => item[`${dataIdx}IDC`] || 0)))
            })
          })
        })
        .catch(error => console.log('error get day data', error))
    }
    const fetchDcTemperature = async () => {
      const dcParam = [...new Set(selectIndex.value.filter(e => /.*IDC$/.test(e)).map(item => (item.replace(/^0{1}/, '').replace(/IDC/, ''))))].sort()
      axios
        .get('/device/daily_dc_temperature', {
          params: {
            date: dateSelected.value,
            deviceId: selectDevice.value.join(),
            dc_param: dcParam.join(','),
            emi_deviceId: emiDeviceId.value,
          },
        })
        .then(response => {
          dcTemperatureData.datasets = []
          Object.entries(response.data).forEach(([deviceId, data]) => {
            colors.reset()
            dcParam.forEach(param => {
              const deviceData = getInverterData(deviceId)
              const dataIdx = `${param.padStart(2, '0')}`
              dcTemperatureData.datasets.push({
                data: data[dataIdx].map(item => ({ y: parseFloat((item[`${dataIdx}IDC`] || 0).toFixed(3)), x: parseFloat((item.PVTMP || 0).toFixed(3)), date: item.publishTimestamp })),
                label: `${deviceData.device_name || 'N/A'} - MPPT${param}`,
                backgroundColor: colors.color(),
                borderColor: colors.color(),
              })
              colors.next()
            })
          })
        })
        .catch(error => console.log('error get day data', error))
    }

    const queryData = () => {
      const yAxis = [...new Set(indexes[0].children.filter(item => selectIndex.value.includes(item.id)).map(el => el.yAxis))]
      Object.keys(analysisOptions.scales).forEach(key => {
        analysisOptions.scales[key].display = yAxis.includes(key)
      })
      analysisOptions.scales.x.display = true
      switch (toggleDayType.value) {
        case 0:
        default:
          fetchDayData()
          break
        case 1:
          fetchMonthData()
          break
        case 2:
          fetchYearData()
          break
        case 3:
          fetchTotalData()
          break
      }
    }

    const showHideDcChart = () => {
      showDcPower.value = selectIndex.value.some(e => /.*(IDC|VDC)$/.test(e))
      showTemperature.value = selectIndex.value.some(e => /.*IDC$/.test(e))
    }

    fetchDayData()

    watch(toggleDayType, () => {
      Object.keys(analysisOptions.scales).forEach(key => {
        if (key.startsWith('y')) {
          analysisOptions.scales[key].display = false
        }
      })
      switch (toggleDayType.value) {
        case 0:
        default:
          analysisOptions.scales.y.display = true
          analysisOptions.scales.x.time = {
            unit: 'hour',
            displayFormats: {
              hour: 'HH:mm',
            },
          }
          showDayOnlyIndex.value = true
          setTimeout(() => {
            selectIndex.value = ['PACTO']
            queryData()
          }, 100)
          break
        case 1:
          showDcPower.value = false
          showTemperature.value = false
          analysisOptions.scales.ykWh.display = true
          analysisOptions.scales.x.time = {
            unit: 'day',
          }
          showDayOnlyIndex.value = false
          setTimeout(() => {
            selectIndex.value = ['ENETO']
            queryData()
          }, 100)
          break
        case 2:
          showDcPower.value = false
          showTemperature.value = false
          analysisOptions.scales.ykWh.display = true
          analysisOptions.scales.x.time = {
            unit: 'month',
            displayFormats: {
              month: 'MMM',
            },
          }
          showDayOnlyIndex.value = false
          setTimeout(() => {
            selectIndex.value = ['ENETO']
            queryData()
          }, 100)
          break
        case 3:
          showDcPower.value = false
          showTemperature.value = false
          analysisOptions.scales.ykWh.display = true
          analysisOptions.scales.x.time = {
            unit: 'year',
          }
          showDayOnlyIndex.value = false
          setTimeout(() => {
            selectIndex.value = ['ENETO']
            queryData()
          }, 100)
          break
      }
    })

    watch(selectIndex, () => {
      showHideDcChart()
    })

    store.watch(state => state.devices.INV, INV => {
      setTimeout(() => {
        selectDevice.value = [INV[0]?.device_id]
        queryData()
      }, 100)
    })

    return {
      devices,
      indexes,
      selectDevice,
      selectIndex,
      toggleDayType,
      dateSelected,
      datePicker,
      pvImage,
      visibleLightbox,

      analysisLoading,
      analysisOptions,
      analysisData,
      showDcPower,
      dcPowerData,
      showTemperature,
      dcTemperatureData,
      queryData,
      showPvImage,
      onHideLightbox,

      availableIndexes,

      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
