<template>
  <div>
    <v-row class="justify-center">
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-row class="match-height">
          <v-col cols="12">
            <statistics-card-small
              title="Daily Energy Yield"
              :value="cardData.dailyEnergy"
              value-unit="kWh"
              :icon="mdiSolarPower"
              icon-color="rgba(0, 180, 156, 1)"
            ></statistics-card-small>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-row class="match-height">
          <v-col cols="12">
            <statistics-card-small
              title="Lifetime Energy"
              :value="cardData.lifetimeEnergy.value"
              :value-unit="cardData.lifetimeEnergy.unit"
              :icon="mdiLightningBolt"
              icon-color="rgba(0, 174, 239, 1)"
            ></statistics-card-small>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-row class="match-height">
          <v-col cols="12">
            <statistics-card-small
              title="Current Power"
              :value="cardData.currentPower"
              value-unit="kW"
              :icon="mdiSolarPanel"
              icon-color="rgba(0, 180, 156, 1)"
            ></statistics-card-small>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-row class="match-height">
          <v-col cols="12">
            <statistics-card-small
              title="Total Irradiance"
              :value="cardData.totalIrradiance"
              value-unit="W/m<sup>2</sup>"
              :icon="mdiSunWirelessOutline"
              icon-color="rgba(218, 28, 92, 1)"
            ></statistics-card-small>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-row class="match-height">
          <v-col cols="12">
            <statistics-card-small
              title="Performance Ratio"
              :value="cardData.performanceRatio"
              value-unit="%"
              :icon="mdiTrendingUp"
              icon-color="rgba(35, 33, 39, 1)"
            ></statistics-card-small>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-row class="match-height">
          <v-col cols="12">
            <statistics-card-small
              title="Specific Energy"
              :value="cardData.specificEnergy"
              value-unit="kWh/kWp"
              :icon="mdiCarBattery"
              icon-color="rgba(72, 47, 146, 1)"
            ></statistics-card-small>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-row class="match-height">
          <v-col cols="12">
            <statistics-card-small
              title="Income"
              :value="cardData.income.value"
              :value-unit="cardData.income.unit"
              :icon="mdiCashMultiple"
              icon-color="rgba(35, 33, 39, 1)"
            ></statistics-card-small>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-row class="match-height">
          <v-col cols="12">
            <statistics-card-small
              title="Co<sub class='me-1'>2</sub> Reduction"
              :value="cardData.co2Reduction.value"
              value-unit="tons of CO<sub class='me-1'>2e</sub>"
              :icon="mdiMoleculeCo2"
              icon-color="rgba(35, 33, 39, 1)"
            ></statistics-card-small>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col
                lg="8"
              >
                <energy-flow-diagram :site-id="siteId" @update="updatePie"></energy-flow-diagram>
              </v-col>
              <v-col lg="4">
                <div class="d-flex flex-lg-column fill-height">
                  <div class="px-lg-3 mt-lg-6">
                    <h3 class="text-center mb-4">
                      {{ siteInfo.site_name_en }}
                    </h3>
                    <div>
                      <span class="font-weight-bold">Capacity:</span> {{ siteInfo.capacity || '-'}} kW
                    </div>
                    <div>
                      <span class="font-weight-bold">Number of Inverters:</span> {{ siteInfo.num_of_inv || '-'}}
                    </div>
                    <div>
                      <span class="font-weight-bold">Location:</span> {{ siteInfo.location || '-'}}
                    </div>
                  </div>
                  <div class="flex-fill d-flex justify-center">
                    <vue-apex-charts
                      class="align-self-center"
                      type="pie"
                      height="300"
                      :options="overviewGenerate.chartOptions"
                      :series="overviewGenerate.series"
                    />
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="row-chart">
      <v-col
        cols="12"
        md="6"
      >
        <current-day-power-card :site-id="siteId"></current-day-power-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <energy-yield-comparison-card :site-id="siteId" :site-info="siteInfo"></energy-yield-comparison-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang="scss">
.row-chart {
  .v-card__title {
    height: 80px;
  }
  .month-select {
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 1000;
    max-width: 110px;
  }
}
</style>

<script>
import {
  mdiSolarPower,
  mdiSolarPanel,
  mdiLightningBolt,
  mdiTrendingUp,
  mdiCarBattery,
  mdiNaturePeople,
  mdiSunWirelessOutline,
  mdiCashMultiple,
  mdiMoleculeCo2,
} from '@mdi/js'

import {
  ref, reactive, watch, onBeforeUnmount,
} from '@vue/composition-api'
import axios from '@axios'
import VueApexCharts from 'vue-apexcharts'
import StatisticsCardSmall from './StatisticsCardSmall.vue'
import CurrentDayPowerCard from './CurrentDayPowerCard.vue'
import EnergyYieldComparisonCard from './EnergyYieldComparisonCard.vue'
import EnergyFlowDiagram from './EnergyFlowDiagram.vue'

export default {
  components: {
    VueApexCharts,
    EnergyFlowDiagram,
    EnergyYieldComparisonCard,
    StatisticsCardSmall,
    CurrentDayPowerCard,
  },
  props: {
    siteId: String,
    siteInfo: Object,
  },
  setup(props) {
    // Todo: change siteId, site co2
    const siteCapacity = ref(100.040) // 100.040 = Donbosco's capacity

    const cardData = reactive({
      dailyEnergy: '0.000',
      lifetimeEnergy: {
        value: '0.000',
        unit: 'Wh',
      },
      currentPower: '0.000',
      performanceRatio: '0.00',
      specificEnergy: '0.000',
      totalIrradiance: '0.00',
      income: {
        value: '0',
        unit: 'kTHB',
      },
      co2Reduction: {
        value: '0.000',
        unit: 'tons of Co2',
      },
    })
    let timeoutLoadData = null
    const loadData = async () => {
      await axios
        .get(`/device/getlatest?site_id=${props.siteId}`)
        .then(response => {
          const { INV_SUM, EMI } = response.data.data
          const CO2Multiplier = (process.env.VUE_APP_CO2Multiplier || 0)
          const incomeMultiplier = (process.env.VUE_APP_IncomeMultiplier || 0)

          const specificEnergy = (INV_SUM.ENETO_TOTAL_SUM / siteCapacity.value)
          const dailyEnergy = INV_SUM.ENETO_TOTAL_SUM
          const totalIRRDA = EMI.reduce((prevValue, current) => prevValue + current.IRRDA, 0)
          const totalIRRTO = EMI.reduce((prevValue, current) => prevValue + current.IRRTO, 0)
          const lifetimeEnergy = INV_SUM.ENETO

          cardData.dailyEnergy = dailyEnergy.toFixed(3)
          if (lifetimeEnergy > 1000) {
            cardData.lifetimeEnergy.unit = 'MWh'
            cardData.lifetimeEnergy.value = (lifetimeEnergy / 1000).toFixed(3)
          } else {
            cardData.lifetimeEnergy.unit = 'Wh'
            cardData.lifetimeEnergy.value = (lifetimeEnergy).toFixed(3)
          }
          cardData.income.value = ((lifetimeEnergy * incomeMultiplier) / 1000).toFixed(3)
          cardData.co2Reduction.value = ((lifetimeEnergy * CO2Multiplier) / 1000).toFixed(3)
          cardData.currentPower = INV_SUM.PACTO.toFixed(3)
          cardData.specificEnergy = specificEnergy.toFixed(3)
          cardData.performanceRatio = totalIRRDA <= 0 ? '0.00' : ((specificEnergy / totalIRRDA) * 100).toFixed(2)
          cardData.totalIrradiance = totalIRRTO.toFixed(2)
        })
        .catch(error => {
          console.log('error load data ', error.response)
        })

      timeoutLoadData = setTimeout(loadData, 15000)
    }

    const overviewGenerate = reactive({
      series: [0, 0],
      chartOptions: {
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        labels: ['Solar', 'Grid/Generator'],
        legend: {
          show: false,
          position: 'bottom',
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: false,
                name: {
                  color: '#ffffff',
                },
                value: {
                  color: '#ffffff',
                },
              },
            },
          },
        },
        colors: ['rgb(0, 180, 156)', 'rgb(218, 28, 92)'],
        dataLabels: {
          enabled: true,
          formatter(val, opt) {
            return [opt.w.globals.labels[opt.seriesIndex], `${val.toFixed(2)}%`]
          },
        },
      },
    })
    const updatePie = data => {
      overviewGenerate.series = data
    }

    // Todo: cancel axios if data is fetching
    watch(() => props.siteId, () => {
      clearTimeout(timeoutLoadData)
      loadData()
    })

    if (props.siteId) {
      loadData()
    }

    onBeforeUnmount(() => {
      clearTimeout(timeoutLoadData)
    })

    return {
      siteCapacity,
      cardData,
      overviewGenerate,

      updatePie,

      mdiSolarPower,
      mdiSolarPanel,
      mdiLightningBolt,
      mdiTrendingUp,
      mdiCarBattery,
      mdiNaturePeople,
      mdiSunWirelessOutline,
      mdiCashMultiple,
      mdiMoleculeCo2,
    }
  },
}
</script>
