<template>
  <v-card>
    <v-card-title>
      <span>Energy Yield Comparison</span>
      <v-spacer></v-spacer>
      <span class="subtitle-2">
        <v-btn-toggle
          v-model="toggleEnergyYieldComparison"
        >
          <v-btn
            depressed
            small
          >
            Month
          </v-btn>
          <v-btn
            depressed
            small
          >
            Year
          </v-btn>
          <v-btn
            depressed
            small
          >
            Total
          </v-btn>
        </v-btn-toggle>
      </span>
    </v-card-title>
    <v-card-text>
      <div
        v-if="toggleEnergyYieldComparison==0"
        class="month-select"
      >
        <v-select
          v-model="monthSelect"
          :items="chartConfig.months"
          item-value="v"
          item-text="t"
          hide-details
          single-line
          dense
        ></v-select>
      </div>
      <vue-apex-charts
        type="bar"
        height="400"
        :options="chartConfig.energyYieldComparison.chartOptions"
        :series="chartConfig.energyYieldComparison.series"
      />
      <v-overlay
        v-model="showOverlay"
        absolute
        color="white"
        opacity="0.75"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
.row-chart {
  .v-card__title {
    height: 80px;
  }
  .month-select {
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 1000;
    max-width: 110px;
  }
}
</style>

<script>
import VueApexCharts from 'vue-apexcharts'

import {
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import axios from '@axios'
import dayjs from 'dayjs'
import chartConfig from './chartConfig'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    siteId: { type: String, default: '' },
    siteInfo: { type: Object, default: null },
  },
  data() {
    return {
      chartConfig,
    }
  },

  setup(props) {
    const showOverlay = ref(false)
    const toggleEnergyYieldComparison = ref(0)
    const monthSelect = ref((new Date()).getMonth())
    const years = computed(() => {
      const crYear = (new Date()).getFullYear()
      const stYear = dayjs(props.siteInfo.start_date || (new Date())).format('YYYY')
      const yrs = []
      // eslint-disable-next-line no-plusplus
      for (let i = stYear; i <= crYear; i++) yrs.push(i)

      return yrs.join()
    })

    const hideOverlay = () => {
      showOverlay.value = false
    }

    const showFetching = () => {
      showOverlay.value = true
    }

    const fetchMonthData = async () => {
      showFetching()
      const selectedmonth = dayjs().set('month', monthSelect.value)
      const daysInMonth = selectedmonth.daysInMonth()
      chartConfig.energyYieldComparison.chartOptions = {
        ...chartConfig.energyYieldComparison.chartOptions,
        ...{
          xaxis: {
            type: 'category',
            categories: [...Array(daysInMonth).keys()].map(item => (item + 1).toString().padStart(2, '0')),
          },
        },
      }

      chartConfig.energyYieldComparison.series = []
      axios
        .get(`/device/daily_sum_en?site_id=${props.siteId}&years=${years.value}&month=${selectedmonth.format('MM')}`)
        .then(response => {
          Object.entries(response.data).forEach(([yearMonth, data]) => {
            const currentData = new Array(daysInMonth).fill(null)
            Object.entries(data).forEach(([, item]) => {
              const idx = dayjs(item.date).format('DD').toString() - 1
              currentData.splice(idx, 1, parseFloat(item.ENETO_TOTAL.toFixed(3)))
            })
            chartConfig.energyYieldComparison.series.push({
              name: dayjs(`${yearMonth}-25`, 'YYYY-MM-DD').format('YYYY'),
              data: currentData,
            })
          })
        })
        .catch(error => console.log('error get monthly summary', error))
        .finally(hideOverlay)
    }

    const fetchYearData = async () => {
      showFetching()
      chartConfig.energyYieldComparison.series = []
      chartConfig.energyYieldComparison.chartOptions = {
        ...chartConfig.energyYieldComparison.chartOptions,
        ...{
          xaxis: {
            type: 'category',
            categories: [
              '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
            ],
          },
        },
      }
      axios
        .get(`/device/monthly_sum_en?site_id=${props.siteId}&years=${years.value}`)
        .then(response => {
          Object.entries(response.data).forEach(([year, data]) => {
            const currentData = new Array(12).fill(null)
            data.forEach(item => {
              const idx = dayjs(`${item.date}-25`, 'YYYY-MM-DD').format('M').toString() - 1
              currentData.splice(idx, 1, parseFloat(item.ENETO.toFixed(3)))
            })
            chartConfig.energyYieldComparison.series.push({
              name: year,
              data: currentData,
            })
          })
        })
        .catch(error => console.log('error get monthly summary', error))
        .finally(hideOverlay)
    }

    const fetchTotalData = async () => {
      showFetching()
      chartConfig.energyYieldComparison.series = []
      chartConfig.energyYieldComparison.chartOptions = {
        ...chartConfig.energyYieldComparison.chartOptions,
        ...{
          xaxis: {
            type: 'category',
            categories: years.value.split(','),
          },
        },
      }
      axios
        .get(`/device/yearly_sum_en?site_id=${props.siteId}&years=${years.value}`)
        .then(response => {
          Object.entries(response.data).forEach(([year, data]) => {
            chartConfig.energyYieldComparison.series.push({
              name: year,
              data: [parseFloat(data.ENETO.toFixed(3)) || null],
            })
          })
        })
        .catch(error => console.log('error get total summary', error))
        .finally(hideOverlay)
    }

    // Todo: cancel axios if data is fetching
    watch(() => props.siteId, () => {
      if (toggleEnergyYieldComparison.value !== 0) {
        toggleEnergyYieldComparison.value = 0
      } else {
        fetchMonthData()
      }
    })

    watch(toggleEnergyYieldComparison, value => {
      switch (value) {
        case 1:
          fetchYearData()
          break
        case 2:
          fetchTotalData()
          break
        case 0:
        default:
          fetchMonthData()
          break
      }
    })
    watch(monthSelect, () => {
      fetchMonthData()
    })

    if (props.siteId) {
      fetchMonthData()
    }

    return {
      chartConfig,
      years,
      showOverlay,
      toggleEnergyYieldComparison,
      monthSelect,

      showFetching,
      fetchMonthData,
      fetchYearData,
    }
  },
}
</script>
