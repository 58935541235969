import numeral from 'numeral'

export default {
  months: [
    {
      v: 0,
      t: 'January',
    },
    {
      v: 1,
      t: 'February',
    },
    {
      v: 2,
      t: 'March',
    },
    {
      v: 3,
      t: 'April',
    },
    {
      v: 4,
      t: 'May',
    },
    {
      v: 5,
      t: 'June',
    },
    {
      v: 6,
      t: 'July',
    },
    {
      v: 7,
      t: 'August',
    },
    {
      v: 8,
      t: 'September',
    },
    {
      v: 9,
      t: 'October',
    },
    {
      v: 10,
      t: 'November',
    },
    {
      v: 11,
      t: 'December',
    },
  ],
  energyYieldComparison: {
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'center',
        fontSize: '14px',
        fontFamily: '"Gotham", sans-serif',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        type: 'category',
        categories: [],

        // labels: {
        //   format: 'HH:mm',
        //   datetimeUTC: false,
        // },
        // tickAmount: 10,
        // categories: [
        //   '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
        // ],
      },
      yaxis: {
        title: {
          text: 'Energy Yield (kWh)',
        },
        labels: {
          // Should not have decimal
          formatter: value => value,
        },
      },
      tooltip: {
        enable: true,
        shared: true,
        intersect: false,
        followCursor: true,
        y: {
          show: true,
          formatter: value => {
            if (value === null) return 0

            return numeral(value).format('0,0.000')
          },
        },
      },
      colors: ['rgb(0, 174, 239)', 'rgb(0, 180, 156)', 'rgb(218, 28, 92)'],
    },
    series: [],
  },

  analytic: {
    // series: [{
    //   name: 'Income',
    //   type: 'line',
    //   data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
    // }, {
    //   name: 'Cashflow',
    //   type: 'line',
    //   data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
    // }, {
    //   name: 'Revenue',
    //   type: 'line',
    //   data: [20, 29, 37, 36, 44, 45, 50, 58]
    // }],
    series: [],
    chartOptions: {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'HH:mm',
          datetimeUTC: false,
        },
        // min: new Date(new Date().setHours(0, 0, 0, 0)).getTime(), // start date
        // max: new Date(new Date().setHours(24, 0, 0, 0)).getTime(), // end date
        // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
      },
      // yaxis: [
      //   {
      //     axisTicks: {
      //       show: true,
      //     },
      //     axisBorder: {
      //       show: true,
      //       color: '#008FFB',
      //     },
      //     labels: {
      //       style: {
      //         colors: '#008FFB',
      //       }
      //     },
      //     title: {
      //       text: 'Income (thousand crores)',
      //       style: {
      //         color: '#008FFB',
      //       },
      //     },
      //     tooltip: {
      //       enabled: true,
      //     },
      //   },
      //   {
      //     seriesName: 'Income',
      //     opposite: true,
      //     axisTicks: {
      //       show: true,
      //     },
      //     axisBorder: {
      //       show: true,
      //       color: '#00E396',
      //     },
      //     labels: {
      //       style: {
      //         colors: '#00E396',
      //       },
      //     },
      //     title: {
      //       text: 'Operating Cashflow (thousand crores)',
      //       style: {
      //         color: '#00E396',
      //       },
      //     },
      //   },
      //   {
      //     seriesName: 'Revenue',
      //     opposite: true,
      //     axisTicks: {
      //       show: true,
      //     },
      //     axisBorder: {
      //       show: true,
      //       color: '#FEB019',
      //     },
      //     labels: {
      //       style: {
      //         colors: '#FEB019',
      //       },
      //     },
      //     title: {
      //       text: 'Revenue (thousand crores)',
      //       style: {
      //         color: '#FEB019',
      //       },
      //     },
      //   },
      // ],
      tooltip: {
        // fixed: {
        //   enabled: true,
        //   position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        //   offsetY: 30,
        //   offsetX: 60,
        // },
      },
      legend: {
        show: false,
      },
    },
  },

  currentDayPower: {
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'center',
        fontSize: '14px',
        fontFamily: '"Gotham", sans-serif',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'HH:mm',
          datetimeUTC: false,
        },
        tickAmount: 10,
        min: new Date(new Date().setHours(0, 0, 0, 0)).getTime(), // start date
        max: new Date(new Date().setHours(24, 0, 0, 0)).getTime(), // end date
      },
      yaxis: {
        title: {
          text: 'Power (kW)',
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.5,
          opacityTo: 0.2,
          stops: [0, 100, 100, 100],
        },
      },
      tooltip: {
        enable: true,
        shared: true,
        followCursor: true,
        x: {
          show: true,
          format: 'dd-MM-yyyy HH:mm:ss',
          formatter: undefined,
        },
      },
      colors: ['#A4DEF9', '#F05030'],
    },
    series: [],
  },
}
