<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="iconColor"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <!-- eslint-disable vue/no-v-html -->
            <p
              class="text-xs mb-0"
              v-html="title"
            >
            </p>
            <div class="d-flex align-baseline">
              <h3 class="text-2xl font-weight-semibold me-2">
                {{ valueFormatted }}
              </h3>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <small v-html="valueUnit" />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  components: {
  },
  props: {
    title: { type: String, default: '' },
    value: { type: String, default: '0.000' },
    valueUnit: { type: String, default: '' },
    icon: { type: String, default: '' },
    iconColor: { type: String, default: '' },
    click: { type: Boolean, default: false },

    // click: { type: Function, default: () => {} },
  },
  setup(props) {
    const valueFormatted = computed(() => props.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))

    return {
      valueFormatted,
    }
  },
}
</script>

<style lang="scss">
#chart-stats-total-sales {
  max-width: 150px;
  height: auto;
  .apexcharts-pie {
    .apexcharts-pie-series .apexcharts-pie-area {
      stroke-width: 10;
    }
  }
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
        font-size: 1.25rem;
      }
      &.apexcharts-datalabel-label {
        font-size: 0.75rem;
        margin-bottom: 2rem;
        transform: translateY(-7px);
      }
    }
  }
}

.v-application {
  &.theme--dark {
    #chart-stats-total-sales {
      path {
        stroke: #312d4b;
      }
    }
  }
}
</style>
