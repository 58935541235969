<template>
  <scatter-chart
    :height="400"
    :chart-data="data"
    :options="chartOptions"
  />
</template>

<script>
import { reactive, ref, watch } from '@vue/composition-api'
import { ScatterChart } from 'vue-chart-3'

export default {
  components: {
    ScatterChart,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    xTitle: {
      type: String,
      default: '',
    },
    yTitle: {
      type: String,
      default: '',
    },
    annotation: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const maxY = ref(10)
    const chartOptions = reactive({
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 800,
      title: {
        display: false,
        text: '',
      },
      elements: {
        point: {
          borderColor: 'transparent',
          pointBorderWidth: 0,
          pointHoverBorderWidth: 0,
          pointRadius: 3,
        },
      },
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: props.xTitle,
          },
          scaleLabel: {
            display: true,
          },
          gridLines: {
            display: true,
          },
        },
        y: {
          title: {
            display: true,
            text: props.yTitle,
          },
          max: maxY,
        },
      },
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          callbacks: {
            label(context) {
              return `${context.raw.date} : (${context.parsed.x}, ${context.parsed.y})`
            },
          },
        },
      },
    })

    if (props.annotation) {
      chartOptions.plugins.annotation = {
        annotations: {
          ref200: {
            type: 'line',
            yMin: 1.8,
            yMax: 1.8,
            borderColor: '#BDBDBD',
            borderWidth: 1,
            value: 1.8,
            label: {
              backgroundColor: 'transparent',
              color: 'rgb(0, 0, 0, 0.5)',
              content: '200 W/𝑚2',
              enabled: true,
              padding: {
                left: 2,
                bottom: 13,
              },
              position: 'start',
            },
          },
          ref400: {
            type: 'line',
            yMin: 3.8,
            yMax: 3.8,
            borderColor: '#BDBDBD',
            borderWidth: 1,
            value: 3.8,
            label: {
              backgroundColor: 'transparent',
              color: 'rgb(0, 0, 0, 0.5)',
              content: '400 W/𝑚2',
              enabled: true,
              padding: {
                left: 2,
                bottom: 13,
              },
              position: 'start',
            },
          },
          ref700: {
            type: 'line',
            yMin: 6.3,
            yMax: 6.3,
            borderColor: '#BDBDBD',
            borderWidth: 1,
            value: 6.3,
            label: {
              backgroundColor: 'transparent',
              color: 'rgb(0, 0, 0, 0.5)',
              content: '700 W/𝑚2',
              enabled: true,
              padding: {
                left: 2,
                bottom: 13,
              },
              position: 'start',
            },
          },
          ref1000: {
            type: 'line',
            yMin: 9,
            yMax: 9,
            borderColor: '#BDBDBD',
            borderWidth: 1,
            value: 9,
            label: {
              backgroundColor: 'transparent',
              color: 'rgb(0, 0, 0, 0.5)',
              content: '1000 W/𝑚2',
              enabled: true,
              padding: {
                left: 2,
                bottom: 13,
              },
              position: 'start',
            },
          },
          refVMP654: {
            type: 'line',
            xMin: 654.48,
            xMax: 654.48,
            borderColor: '#BDBDBD',
            borderWidth: 1,
            value: 654.48,
            label: {
              backgroundColor: 'transparent',
              color: 'rgb(0, 0, 0, 0.5)',
              content: '654.48',
              enabled: false,
              padding: {
                left: 2,
                bottom: 13,
              },
              position: 'middle',
            },
          },
          refVMP690: {
            type: 'line',
            xMin: 690.84,
            xMax: 690.84,
            borderColor: '#BDBDBD',
            borderWidth: 1,
            value: 690.84,
            label: {
              backgroundColor: 'transparent',
              color: 'rgb(0, 0, 0, 0.5)',
              content: '690.84',
              enabled: false,
              padding: {
                left: 2,
                bottom: 13,
              },
              position: 'middle',
            },
          },
        },
      }
    }

    watch(() => props.data.max, () => {
      maxY.value = Math.ceil(Math.max(...props.data.max))
    })

    return {
      chartOptions,
    }
  },
}
</script>
