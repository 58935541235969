<template>
  <v-row>
    <v-col
      cols="12"
      md="9"
      class="d-flex align-center"
    >
      <v-btn
        outlined
        color="primary"
        class="me-3"
        :class="{ 'v-btn--active' : currentActive === 1 } "
        @click="clickButton(1)"
      >
        Overview
      </v-btn>
      <v-btn
        outlined
        color="primary"
        class="me-3"
        :class="{ 'v-btn--active' : currentActive === 2 } "
        @click="clickButton(2)"
      >
        Analysis
      </v-btn>
      <v-btn
        outlined
        color="primary"
        class="me-3"
        :class="{ 'v-btn--active' : currentActive === 3 } "
        @click="clickButton(3)"
      >
        Alarm
      </v-btn>
    </v-col>
    <v-col
      v-if="$store.state.sites.list.length > 1"
      cols="12"
      md="3"
      class="d-flex justify-end align-center"
    >
      <v-select
        v-model="siteId"
        :items="$store.state.sites.list"
        item-value="site_id"
        item-text="site_name_en"
        label="Site"
        hide-details
        filled
        background-color="white"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>

import { ref, watch } from '@vue/composition-api/dist/vue-composition-api'
import store from '@/store'

export default {
  setup(props, { emit }) {
    const currentActive = ref(1)
    const siteId = ref(store.getters['sites/siteId'])

    const clickButton = menu => {
      if (currentActive.value !== menu) {
        currentActive.value = menu
        emit('change-sub-nav', currentActive)
      }
    }

    store.watch(state => state.sites.selected, selectedSite => {
      siteId.value = selectedSite.site_id
    })

    watch(siteId, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        store.commit('sites/SET_SELECTED', newValue)
      }
    })

    return {
      currentActive,
      siteId,

      clickButton,
    }
  },
}
</script>
