const colors = {}
let current = 0

colors.colors = ['rgb(0, 174, 239)', 'rgb(0, 180, 156)', 'rgb(218, 28, 92)', 'rgb(72, 47, 146)', 'rgb(35, 33, 39)']
colors.color = () => colors.colors[current]
colors.next = () => {
  current = current + 1 > colors.colors.length - 1 ? 0 : current + 1
}
colors.reset = () => (current = 0)

export default colors
