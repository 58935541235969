<template>
  <easel-canvas
    ref="canvas"
    :width="settings.width"
    :height="settings.height"
    :viewport-width="settings.viewPoint.width"
    :viewport-height="settings.viewPoint.height"
  >
    <easel-container
      scale=".3"
      :x="10"
      :y="0"
    >
      <easel-bitmap
        image="/images/solar-panel.png"
        :x="0"
        :y="0"
        :align="['left','top']"
        :alpha="deviceSolar.enable? 1 : 0.3"
      >
      </easel-bitmap>
      <easel-text
        :text="`Total Efficiency ${deviceSolar.eff}%`"
        font="50px 'Inter'"
        :x="0"
        :y="400"
      >
      </easel-text>
    </easel-container>
    <easel-container
      scale=".3"
      :x="10"
      :y="185"
    >
      <easel-bitmap
        image="/images/generator.png"
        :x="0"
        :y="0"
        :align="['left','top']"
        :alpha="deviceGenerator.enable? 1 : 0.3"
      >
      </easel-bitmap>
    </easel-container>
    <easel-container
      scale=".3"
      :x="10"
      :y="370"
    >
      <easel-bitmap
        image="/images/grid.png"
        :x="0"
        :y="0"
        :align="['left','top']"
        :alpha="deviceGrid.enable? 1 : 0.3"
      >
      </easel-bitmap>
    </easel-container>
    <easel-container
      scale=".3"
      :x="440"
      :y="0"
    >
      <easel-bitmap
        image="/images/inverter.png"
        :x="0"
        :y="0"
        :align="['left','top']"
      >
      </easel-bitmap>
    </easel-container>
    <easel-container
      scale=".3"
      :x="440"
      :y="370"
    >
      <easel-bitmap
        image="/images/battery.png"
        :x="0"
        :y="0"
        :align="['left','top']"
        :alpha="deviceBattery.enable? 1 : 0.3"
      >
      </easel-bitmap>
    </easel-container>
    <easel-container
      scale=".3"
      :x="765"
      :y="0"
    >
      <easel-bitmap
        image="/images/load.png"
        :x="0"
        :y="0"
        :align="['left','top']"
      >
      </easel-bitmap>
    </easel-container>

    <energy-flow-line
      ref="solarLine"
      :enable="deviceSolar.enable"
      :start-point="deviceSolar.line.startPoint"
      :lines="deviceSolar.line.lines"
      :points="deviceSolar.line.points"
      :value="deviceSolar.value"
    ></energy-flow-line>
    <energy-flow-line
      ref="generatorLine"
      :enable="deviceGenerator.enable"
      :start-point="deviceGenerator.line.startPoint"
      :lines="deviceGenerator.line.lines"
      :points="deviceGenerator.line.points"
      :value="deviceGenerator.value"
    ></energy-flow-line>
    <energy-flow-line
      ref="gridLine"
      :enable="deviceGrid.enable"
      :start-point="deviceGrid.line.startPoint"
      :lines="deviceGrid.line.lines"
      :points="deviceGrid.line.points"
      :value="deviceGrid.value"
    ></energy-flow-line>
    <energy-flow-line
      ref="generatorGridLine"
      :enable="lineGeneratorGrid.enable"
      :start-point="lineGeneratorGrid.line.startPoint"
      :lines="lineGeneratorGrid.line.lines"
      :points="lineGeneratorGrid.line.points"
      :value="lineGeneratorGrid.value"
    ></energy-flow-line>
    <energy-flow-line
      ref="batteryLine"
      :enable="deviceBattery.enable"
      :start-point="deviceBattery.line.startPoint"
      :lines="deviceBattery.line.lines"
      :points="deviceBattery.line.points"
      :value="deviceBattery.value"
    ></energy-flow-line>
    <energy-flow-line
      ref="loadLine"
      :enable="deviceLoad.enable"
      :start-point="deviceLoad.line.startPoint"
      :lines="deviceLoad.line.lines"
      :points="deviceLoad.line.points"
      :value="deviceLoad.value"
    ></energy-flow-line>

    <easel-text
      ref="solarValue"
      v-if="deviceSolar.enable"
      :text="`${deviceSolar.value.toFixed(2)} kW`"
      font="18px 'Inter'"
      :x="150"
      :y="35"
    />
    <easel-text
      ref="generatorValue"
      v-if="deviceGenerator.enable"
      :text="`${deviceGenerator.value.toFixed(2)} kW`"
      font="18px 'Inter'"
      :x="150"
      :y="225"
    />
    <easel-text
      ref="gridValue"
      v-if="deviceGrid.enable"
      :text="`${deviceGrid.value.toFixed(2)} kW`"
      font="18px 'Inter'"
      :x="150"
      :y="403"
    />
    <easel-text
      ref="batteryValue"
      v-if="deviceBattery.enable"
      :text="`${deviceBattery.value.toFixed(2)} kW`"
      font="18px 'Inter'"
      :x="515"
      :y="225"
    />
    <easel-text
      ref="loadValue"
      v-if="deviceLoad.enable"
      :text="`${deviceLoad.value.toFixed(2)} kW`"
      font="18px 'Inter'"
      :align="['center', 'center']"
      :x="650"
      :y="44"
    />
</easel-canvas>
</template>

<script>

import Vue from 'vue'
import {
  ref, reactive, onMounted, onUnmounted,
} from '@vue/composition-api'
import axios from '@axios'
import EnergyFlowLine from './EnergyFlowLine.vue'
import { watch } from '@vue/composition-api/dist/vue-composition-api'

Vue.use(require('vue-easeljs'))

export default {
  components: {
    EnergyFlowLine,
  },
  props: {
    siteId: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const canvas = ref(null)
    const settings = reactive({
      width: 900,
      height: 500,
      ratio: 500 / 900,
      viewPoint: {
        width: 900,
        height: 500,
      },
    })
    const resizeHandler = () => {
      const { offsetWidth } = canvas.value.$el.parentNode
      if (offsetWidth !== 0) {
        settings.width = offsetWidth
        settings.height = settings.width * settings.ratio
      }
    }
    const deviceSolar = reactive({
      enable: true,
      value: 0,
      eff: 0,
      line: {
        startPoint: { x: 130, y: 58.5 },
        lines: [{
          d: [305, 5],
          x: 130,
          y: 58.5,
        }],
        points: [[420, 58.5, 'right']],
      },
    })
    const deviceGenerator = reactive({
      enable: true,
      value: 0,
      line: {
        startPoint: { x: 130, y: 245 },
        lines: [{
          d: [152.5, 5],
          x: 130,
          y: 245,
        }],
        points: [[278, 245, 'right']],
      },
    })
    const deviceGrid = reactive({
      enable: true,
      value: 0,
      line: {
        startPoint: { x: 130, y: 425 },
        lines: [{
          d: [152.5, 5],
          x: 130,
          y: 425,
        }, {
          d: [5, 180],
          x: 278,
          y: 250,
        }],
        points: [[278, 425, 'right'], [278, 250, 'up']],
        status: 'active',
      },
    })
    const deviceBattery = reactive({
      enable: true,
      value: 0,
      line: {
        startPoint: { x: 503, y: 168 },
        lines: [{
          d: [5, 250],
          x: 498,
          y: 118,
        }],
        points: [[503, 365, 'down']],
        status: 'active',
      },
    })
    const deviceLoad = reactive({
      enable: true,
      value: 0,
      line: {
        startPoint: { x: 560, y: 58.5 },
        lines: [{
          d: [205, 5],
          x: 560,
          y: 58.5,
        }],
        points: [[760, 58.5, 'right']],
        status: 'active',
      },
    })
    const lineGeneratorGrid = reactive({
      enable: true,
      line: {
        startPoint: { x: 278, y: 240 },
        lines: [{
          d: [5, 187],
          x: 278,
          y: 63,
        }],
        points: [[278, 58.5, 'up']],
        status: 'active',
      },
    })

    let timeoutFetchData = null
    const fetchData = async () => {
      await axios
        .get(`/device/en_flow_diagram?site_id=${props.siteId}`)
        .then(response => {
          const {
            solar,
            // eslint-disable-next-line camelcase
            eff_solar,
            grid,
            load,
            generator,
            battery,
          } = response.data

          deviceSolar.enable = solar !== null
          deviceSolar.value = solar ?? 0
          // eslint-disable-next-line camelcase
          deviceSolar.eff = eff_solar ?? 0
          deviceGrid.enable = grid !== null
          deviceGrid.value = grid ?? 0

          deviceLoad.enable = load !== null
          deviceLoad.value = load ?? 0
          deviceGenerator.enable = generator !== null
          deviceGenerator.value = generator ?? 0
          lineGeneratorGrid.enable = load !== null || generator !== null
          lineGeneratorGrid.value = lineGeneratorGrid.enable ? (deviceGenerator.value + deviceGenerator.value) : 0

          deviceBattery.enable = battery !== null
          deviceBattery.value = battery ?? 0

          // Check if battery is using or storing to reversing flow
          if (deviceBattery.value < 0) { // Charging
            deviceBattery.line.startPoint = { x: 503, y: 118 }
            deviceBattery.line.points = [[503, 365, 'down']]
          } else { // Using
            deviceBattery.line.startPoint = { x: 498, y: 365 }
            deviceBattery.line.points = [[498, 118, 'up']]
          }

          emit('update', [parseFloat(deviceSolar.value.toFixed(2)), parseFloat((deviceGrid.value + deviceGenerator.value).toFixed(2))])
        })
        .catch(error => {
          console.log('error load energy flow data ', error.response)
        })
      timeoutFetchData = setTimeout(fetchData, 15000)
    }

    // Todo: cancel axios if data is fetching
    watch(() => props.siteId, () => {
      clearTimeout(timeoutFetchData)
      fetchData()
    })

    if (props.siteId) {
      fetchData()
    }

    onMounted(() => {
      window.addEventListener('resize', resizeHandler)
      resizeHandler()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
      clearTimeout(timeoutFetchData)
    })

    return {
      canvas,
      settings,

      deviceSolar,
      deviceGenerator,
      deviceGrid,
      deviceBattery,
      deviceLoad,
      lineGeneratorGrid,
    }
  },
}
</script>
